export const meta: BlockMeta = {
  editMode: "both",
  title: "Hero CTA",
  description: "",
  keywords: ["cta"],
  // @ts-ignore
  icon: "megaphone",
  cache: false,
  tags: ["root"],
}

import { defineBlock, EditableText } from "eddev/blocks"
import { GridLines } from "@components/atoms/GridLines"
import { Button } from "@components/atoms/Button"
import { ResponsiveImage } from "@components/atoms/ResponsiveImage"
import { useAdjustedHeight } from "@hooks/useAdjustedHeight"

export default defineBlock("content/hero-cta", (props) => {
  const { addRef } = useAdjustedHeight()

  return (
    <div ref={(el) => (props.gridlines ? el && addRef(el) : undefined)} className={`subtheme-${props.scheme} relative`}>
      {props.gridlines && <GridLines />}
      <div className="w-full h-full flex flex-col items-center justify-between bg-bg pt-[80px]">
        <div className="grid-auto relative items-center pb-8 lg:max-w-[1280px] xl:max-w-[1440px]">
          <div className="col-span-12 md:col-span-10 md:col-start-2 lg:col-span-8 lg:col-start-3">
            <EditableText
              className="type-title-xxl text-text text-center w-full pb-10"
              as="h2"
              store="title"
              placeholder="Enter title..."
            />
          </div>
          <div className="col-span-12 flex gap-4 justify-center">
            {props.button1 && (
              <div className="relative">
                <Button
                  size="xl"
                  style="primary"
                  href={props.button1.url ?? undefined}
                  target={props.button1.target ? "_blank" : undefined}
                >
                  {props.button1.title ?? ""}
                </Button>
                {props.buttonNote && (
                  <div className="absolute top-full left-1/2 -translate-x-1/2 type-title-xs text-textAlt whitespace-nowrap mt-4">
                    {props.buttonNote}
                  </div>
                )}
              </div>
            )}
            {props.button2 && (
              <Button
                size="xl"
                style="secondary"
                rounded
                href={props.button2.url ?? undefined}
                target={props.button2.target ? "_blank" : undefined}
              >
                {props.button2.title ?? ""}
              </Button>
            )}
          </div>
        </div>

        {props.image && (
          <div className="w-full min-h-[500px] [&>div]:w-full flex items-stretch -mt-5 md:mt-0">
            <ResponsiveImage {...props.image} objectFit="cover" />
          </div>
        )}
      </div>
    </div>
  )
})
